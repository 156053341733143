
import { defineComponent } from "vue-demi";
import CatalogMain from "@/components/admin/catalog/index.vue";

export default defineComponent({
  name: "CatalogView",
  components: {
    CatalogMain,
  },
});
